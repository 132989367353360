<script setup>
import { useRoute } from 'vue-router';
import { Validator } from '@vueform/vueform';
import slugify from 'slugify';
import { Rule } from 'json-rules-engine';
import { isEmpty, keyBy } from 'lodash-es';
import { getFieldComponentData, getFieldComponentType, getSubmittedFieldData } from '~/forms/composables/form-field-schema.composable.js';
import FormDetailChecklistField from '~/forms/components/form-detail/form-detail-checklist-field.vue';
import { useFieldSectionVisibility } from '~/forms/composables/form-detail-composable';
import { useJsonRuleEngine } from '~/common/composables/json-engine.js';
import FormBuilderTableSection from '~/forms/components/form-builder/form-builder-table-section.vue';
import FormFieldCopySlug from '~/forms/components/form-field-copy-slug.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store.js';

const props = defineProps({
  step: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {},
  },
  is_step_submitted: {
    type: Boolean,
    default: false,
  },
  form$: {
    type: Object,
  },
  is_preview: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update_checklist']);

slugify.extend({
    '/': '-', '(': '', ')': '', '*': '', '&': '-',
    ':': '-', '\'': '', '"': '', '+': '', '@': '-',
    '!': '', '~': '',
});

const { copy } = useClipboard();
const common_store = useCommonStore();

const route = useRoute();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const form$ = computed(() => props.form$);
const form_detail_store = inject('form_detail_store');
const field_value_map = form_detail_store.field_value_map;
const is_submitted = computed(() => form_detail_store?.form_detail?.status?.name === 'Submitted');
const fields_map = computed(() => keyBy(form_detail_store.form_template.sections.flatMap(section => section.fields).filter(field => field.status === 'active'), 'uid'));
const only_conditional_fields = computed(() => Object.values(fields_map.value).filter(field => field.condition?.conditions));
const { get_field_visibility, get_section_visibility } = useFieldSectionVisibility(form_detail_store, is_submitted);
const { loadJsonEngine } = useJsonRuleEngine();
const auth_store = useAuthStore();
const engine = loadJsonEngine({ is_case_sensitive: true });
const condition_type_map = {
  AND: 'all',
  OR: 'any',
};
const formatted_field_value = {};

function getSections(step) {
  return markRaw(Object.keys(step).length > 0 ? step.sections : form_detail_store?.form_template?.sections);
}

function copy_to_clipboard(section, field = {}, extension = '', suffix = '') {
  if (auth_store.check_split('copy_field_slugs')) {
    const fieldname = slugify(`${props.step?.name || ''} ${section.name} ${field.name || ''}`.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
    const slugified_suffix = slugify(suffix.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
    copy(`{{${fieldname}${extension}${slugified_suffix}}}`);
  }
}

async function load_data($el, field) {
  try {
    if (field_value_map[field?.uid] != null)
      if (field.type === 'number')
        $el.load(`${field_value_map[field?.uid]}`, true);
      else if (field.properties.type === 'signature')
        $el.load(Array.isArray(field_value_map[field?.uid]) ? field_value_map[field?.uid]?.[0] : field_value_map[field?.uid], true);
      else if (field.type === 'auto_number')
        $el.load(field_value_map[field?.uid]?.value, true);
      else if (field.type === 'phone_number')
        setTimeout(() => $el.load(field_value_map[field?.uid], true), 0);
      else
        $el.load(field_value_map[field?.uid],true);      
  }
  catch (e) {
    logger.log(e);
  }
}

function getSignatureData(value) {
  const signature = value?.[0]?.location || value?.[0] || value?.location;
  return signature;
}

function format_signature_data(name, file) {
  if (!file)
    return { [name]: undefined };
  return { [name]: file?.service ? file : ({ service: file.service_object, file_name: file.name, file_size: file.size, mime_type: file.type, meta: file.meta }) };
}

function getActiveFieldElement(section) {
  return section.fields.find(field => field.status === 'active')?.uid;
}

function setInvalidFields(condition_result) {
  const { events, failureEvents } = condition_result;

  failureEvents.forEach((failed_field) => {
    form_detail_store.update_invalid_fields(failed_field.type, true);
  });

  events.forEach((failed_field) => {
    form_detail_store.update_invalid_fields(failed_field.type, false);
  });
}

async function onChangeHandler(new_val, old_val, $el, field) {
  const all_field_rules = [];

  if (field.type === 'member') {
    const value = Array.isArray(new_val) ? new_val.map(value => value.uid) : new_val?.uid;
    formatted_field_value[field.uid] = value;
    form$.value.data[field.uid] = value; // To handle member field condition, formatted the member date from [{name, uid, member}] to [<uid>]
  }

  for (const field of only_conditional_fields.value)
    if (field.condition.conditions) {
      const condition_type = field.condition.type;
      const field_rules = field.condition.conditions[condition_type_map[condition_type]].map(rule => ({ [rule.fact]: field_value_map[rule.fact] }));
      all_field_rules.push(...field_rules);
    }

  const field_values = Object.assign({}, ...all_field_rules);
  const filled_values = get_user_filled_values(field_values);
  const result = await engine.run(filled_values);
  setInvalidFields(result);
}

const checklistValidation = class extends Validator {
  check(checklist) {
    if (Array.isArray(checklist)) {
      const status_array = checklist.map(checklist_item => checklist_item.status).filter(checklist_item_status => checklist_item_status);
      return status_array.length === checklist.length;
    }
    return false;
  }
};

function get_user_filled_values(field_values) {
  let field_value_map = {};

  if (props.is_preview) {
    field_value_map = !isEmpty(form$.value?.data) ? { ...form$.value?.data, ...formatted_field_value } : { ...field_values, ...formatted_field_value };
    return field_value_map;
  }

  if (form_detail_store.form_detail?.workflow)
    field_value_map = { ...field_values, ...formatted_field_value, ...(form$.value?.data || {}) };
  else
    field_value_map = !isEmpty(form$.value?.data) ? { ...form$.value?.data, ...formatted_field_value } : { ...field_values, ...formatted_field_value };

  return field_value_map;
}

onMounted(async () => {
  const fields = Object.values(fields_map.value);
  const all_field_rules = [];

  for (const field of fields) {
    if (field.properties?.type === 'info')
      formatted_field_value[field.uid] = field.config?.placeholder;
    if (field.type === 'member' && !field.config.allow_multiple_selection)
      formatted_field_value[field.uid] = field_value_map[field.uid]?.[0];

    if (field.condition?.conditions) {
      const rules = new Rule({
        conditions: field.condition.conditions,
        event: {
          type: field.uid,
        },
      });
      engine.addRule(rules);

      const condition_type = field.condition.type;
      const field_rules = field.condition.conditions[condition_type_map[condition_type]].map(rule => ({ [rule.fact]: field_value_map[rule.fact] }));
      all_field_rules.push(...field_rules);
    }
  }

  const field_values = Object.assign({}, ...all_field_rules);
  const filled_values = get_user_filled_values(field_values);
  const result = await engine.run(filled_values);
  setInvalidFields(result);
});

const is_read_only = section => is_submitted.value || route.query.submission_history || (props.is_step_submitted && get_section_visibility(section) !== 'pointer-events-auto');
const table_error_section = inject('table_error_section');

function handleAttachmentEvent(file, action) {
  const properties = {
    filesize: file.file_size,
    filetype: file.mime_type || file.extension,
  };
  if (form_detail_store.track_event_view)
    properties.view = form_detail_store.track_event_view;
  form_detail_store.forms_list_store.forms_track_events(`Attachments ${action}`, form_detail_store?.form_detail?.uid, properties);
}
</script>

<template>
  <div>
    <div v-for="section in getSections(props.step)" :key="section.uid" :class="get_section_visibility(section)" class="pb-1">
      <div v-if="section.type !== 'signature' " class="pb-4">
        <div class="font-semibold text-gray-900 text-lg">
          <span class="flex items-center gap-2">
            {{ section.name }}
            <FormFieldCopySlug @copyClick="() => copy_to_clipboard(section, {})" />
          </span>
        </div>
        <div class="text-gray-600 text-xs">
          {{ section.description }}
        </div>
      </div>
      <div v-if="section.type === 'tablev2' && get_section_visibility(section) !== 'hidden'" class="pb-4 pointer-events-auto">
        <FormBuilderTableSection
          :section="section"
          :value_map="field_value_map"
          :options="{ fields_mandatory: true, visibility: get_field_visibility, edit_prefilled_data: section.properties?.can_modify_prefilled_values, update_field_properties: false, disabled: is_read_only(section), disable_summary_calculation: is_read_only(section), add_rows: section.properties?.can_add_rows && !is_step_submitted, delete_row: !is_step_submitted }"
          @update_values="form$.fire('change');form$.elements$[getActiveFieldElement(section)]?.update(true);"
          @header_clicked="(field) => copy_to_clipboard(section, field)"
          @footerNameSlug="(name) => copy_to_clipboard(section, {}, '__raw_summary.', name)"
        />
        <div v-if="table_error_section?.uid === section.uid" :id="section.uid" class="form-color-danger block flex !text-red-700 form-text-small-sm mt-0.5">
          {{ $t('Please fill all the mandatory data') }} - {{ table_error_section?.field_name }}
        </div>
      </div>
      <div v-else-if="section.type === 'signature' && get_section_visibility(section) !== 'hidden'" class="pb-4 pointer-events-auto">
        <div v-for="field in section.fields.filter(field => field.status === 'active')" :key="field.uid" :class="{ 'pointer-events-none': is_read_only(section) }">
          <SignatureElement
            :name="field.uid"
            :label="section.name"
            :description="section.description"
            :disabled="is_read_only(section)"
            :format-data="format_signature_data"
            :rules="['required']"
            :add-classes="{
              ElementLayout: {
                innerContainer: [(is_read_only(section)) && !getSignatureData(field_value_map[field.uid]) ? 'hidden' : ''],
              },
            }"
            @mounted="$el => $el.load(getSignatureData(field_value_map[field.uid]))"
          />
        </div>
      </div>
      <div v-else-if="section.type === 'checklist' && get_section_visibility(section) !== 'hidden'" class="pb-4 pointer-events-auto">
        <div v-for="field in section.fields.filter(field => field.status === 'active')" :key="field.uid">
          <FormDetailChecklistField
            :field="field"
            :value="field_value_map[field.uid]"
            :class="{ 'pointer-events-none': is_read_only(section) }"
            @update="emits('update_checklist', { field, checklist: $event })"
          />
          <div v-if="form$?.elements$[field.uid]?.invalid" class="form-color-danger block flex !text-red-700 form-text-small-sm mt-0.5">
            {{ $t('Please select a value') }}
          </div>
          <HiddenElement :field-name="section.name" :name="field.uid" :meta="true" :rules="['required', checklistValidation]" @mounted="el$ => el$.load(field_value_map[field.uid])" />
        </div>
      </div>
      <div v-else>
        <div v-for="field in section.fields.filter(field => field.type !== 'group')" :key="field.uid" class="pointer-events-auto">
          <template v-if="!form_detail_store.invalid_fields[field.uid]">
            <StaticElement
              v-if="field.properties.type !== 'info' && get_field_visibility(section, field) !== 'pointer-events-auto'" :label="field.name || ' '" class="pb-4" :class="get_field_visibility(section, field)"
              :columns="{
                default: { container: 6, wrapper: field.type === 'attachment' ? 12 : 8, label: 6 },
                sm: { container: 12, label: 12, wrapper: 12 },
                md: { container: 8, label: 4, wrapper: field.type === 'attachment' ? 12 : 8 },
              }"
            >
              <template #info>
                <FormFieldCopySlug class="ml-2 mt-0.5 pointer-events-auto" @copyClick="() => copy_to_clipboard(section, field, field.properties.type === 'signature' ? '.signature' : '')" />
              </template>
              <HawkWysiwygEditorComponent v-if="field.properties.type === 'info'" v-model="field.config.placeholder" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0" />
              <HawkWysiwygEditorComponent v-else-if="field.type === 'long_text'" :model-value="field_value_map[field.uid] || '-'" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0" />
              <div v-else-if="field.type === 'member' && getSubmittedFieldData(field, field_value_map[field.uid]) && getSubmittedFieldData(field, field_value_map[field.uid]) !== '-' " class="flex flex-wrap">
                <HawkMembers
                  :members="[...getSubmittedFieldData(field, field_value_map[field.uid]).members, ...getSubmittedFieldData(field, field_value_map[field.uid]).teams]"
                  type="label" size="badge" :max_badges_to_display="10"
                />
              </div>
              <template v-else-if="field.properties.type === 'signature'">
                <hawk-signature v-if="getSubmittedFieldData(field, field_value_map[field.uid]?.[0]) !== '-'" :model-value="field_value_map[field.uid]?.[0]" class="pointer-events-none" :placeholder="true" />
                <div v-else>
                  -
                </div>
              </template>
              <hawk-sheet-input v-else-if="field.type === 'multi_text'" v-bind="getFieldComponentData(field, { is_submitted: is_read_only(section), is_builder: false })" :editable="false" :model-value="field_value_map[field.uid]" />
              <hawk-attachments-grid
                v-else-if="field.type === 'attachment' && field_value_map[field.uid] && getSubmittedFieldData(field, field_value_map[field.uid]) !== '-'"
                :items="getSubmittedFieldData(field, field_value_map[field.uid])"
                :can_delete="false"
                :enable_description="true"
                class="pointer-events-auto"
                @view="handleAttachmentEvent($event, 'viewed')"
                @download="file => {
                  handleAttachmentEvent(file, 'downloaded');
                }"
              />
              <div v-else-if="(field.type === 'attachment' || field.type === 'member')">
                -
              </div>
              <ul v-else-if="field.properties.type === 'checkbox'" class="list-disc">
                <div v-if="getSubmittedFieldData(field, field_value_map[field.uid]).length === 0">
                  -
                </div>
                <li v-for="(value, index) in getSubmittedFieldData(field, field_value_map[field.uid])" v-else :key="index" class="list-item">
                  {{ value }}
                </li>
              </ul>
              <div v-else-if="!(['long_text', 'member', 'attachment'].includes(field.type))">
                {{ getSubmittedFieldData(field, field_value_map[field.uid]) }}
              </div>
            </StaticElement>
            <div v-else-if="field.status === 'active'">
              <component
                :is="getFieldComponentType(field)"
                v-if="field.type === 'long_text'"
                class="font-normal text-sm pb-4"
                v-bind="getFieldComponentData(field)"
                :default="field_value_map[field.uid]"
                :class="get_field_visibility(section, field)"
                :name="field.uid"
                :label="field.name"
                :description="field.description"
                @mounted="$el => load_data($el, field)"
                @change="(new_val, old_val, $el) => onChangeHandler(new_val, old_val, $el, field)"
              >
                <template #info>
                  <FormFieldCopySlug class="ml-1 mt-0.5" @copyClick="() => copy_to_clipboard(section, field, field.properties.type === 'signature' ? '.signature' : '')" />
                </template>
              </component>
              <component
                :is="getFieldComponentType(field)"
                v-else
                class="font-normal text-sm pb-4"
                v-bind="getFieldComponentData(field, { is_builder: false, form_detail: form_detail_store.form_detail })"
                :class="get_field_visibility(section, field)"
                :name="field.uid"
                :label="field.name"
                :description="field.description"
                @mounted="$el => load_data($el, field)"
                @change="(new_val, old_val, $el) => onChangeHandler(new_val, old_val, $el, field)"
              >
                <template #info>
                  <FormFieldCopySlug class="ml-1 mt-0.5" @copyClick="() => copy_to_clipboard(section, field, field.properties.type === 'signature' ? '.signature' : '')" />
                </template>
              </component>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(div.ProseMirror){
  margin-left:0;
}
</style>
