import { keyBy, uniq } from 'lodash-es';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { inject } from 'vue';
import hawkAssigneeInput from '~/common/components/vueform/hawk-assignee-input.vue';
import CurrencyElement from '~/common/components/vueform/CurrencyElement.vue';
import FormInfoAttachments from '~/forms/molecules/form-info-attachments.vue';
import { all_countries } from '~/common/constants/all-countries.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { currencies } from '~/common/utils/constants.ts';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

function add_rule(array) {
  return array.filter(inner_arr => inner_arr[1]).map(inner_arr => inner_arr[0] + inner_arr[1]);
}
function options(array) {
  return array?.filter(option => !option?.deleted).map(option => ({ value: option?.uid || '', label: option?.name || '' }));
}
function select_file_type(array) {
  const file_type = {
    Video: 'video/*',
    Document: '.doc,.docx',
    Spreadsheet: '.xls,.xlsx',
    Image: 'image/*',
    Audio: 'audio/*',
  };
  return array.map(type => file_type[type]).join(', ');
}
export function useFormFieldConfiguration(configuration = { is_builder: false }) {
  const $services = inject('$services');

  function formatMemberTeam(assignees) {
    if (!Array.isArray(assignees))
      assignees = [assignees];
    return assignees.map(assignee => assignee.uid || assignee);
  }
  const getName = value => field?.config.find(conf => conf.uid === value).name;

  return {
    'short_text': (field) => {
      return {
        'component': 'TextElement',
        'placeholder': field?.config?.placeholder,
        'input-type': 'text',
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable'], ['min:', field?.config?.min_length], ['max:', field?.config?.max_length]]),
        'addons': { before: field?.config?.prefix, after: field?.config?.suffix },
        get_submitted_value(value) {
          const prefix = field?.config?.prefix ? field?.config?.prefix : '';
          const suffix = field?.config?.suffix ? field?.config?.suffix : '';
          return value ? prefix + value + suffix : '-';
        },
      };
    },
    'long_text': (field) => {
      return {
        component: 'WysiwygEditorElement',
        options: {
          editor_enabled: false,
          plugins: ['images', 'tables', 'tags'],
          menu_enabled: true,
          placeholder_text: field?.config?.placeholder,
        },
        rules: [field?.mandatory ? 'required' : 'nullable'].filter(config => config),
        get_submitted_value(value) {
          return value || '-';
        },
      };
    },
    'checkbox': (field) => {
      return {
        component: 'CheckboxgroupElement',
        rules: add_rule([['', field?.mandatory ? 'required' : 'nullable'], ['min:', field?.properties?.minselect], ['max:', field?.properties?.maxselect]]),
        items: options(field?.config),
        addClasses: {
          CheckboxgroupCheckbox: {
            container: 'mb-1',
          },
        },
        get_submitted_value(values) {
          return values ? values.map(getName) : [];
        },
      };
    },
    'radio': (field) => {
      return {
        name: 'radio',
        component: 'RadiogroupElement',
        rules: [field?.mandatory ? 'required' : null].filter(config => config),
        items: options(field?.config),
        get_submitted_value(value) {
          return value ? field?.config.find(conf => conf.uid === value)?.name : '';
        },
      };
    },
    'yes-no': (field) => {
      return {
        name: 'radioTabs',
        component: 'RadiogroupElement',
        view: 'tabs',
        items: options(field?.config),
        rules: [field?.mandatory ? 'required' : null].filter(config => config),
        size: 'sm',
        addClass: {
          wrapper: 'w-[11rem]',
        },
        removeClasses: {
          RadiogroupRadio: {
            wrapper_selected: '!bg-primary-50 !border-primary-500',
          },
        },
        addClasses: {
          RadiogroupRadio: {
            wrapper_selected: '!bg-gray-100',
          },
        },
        get_submitted_value(value) {
          return value ? field?.config.find(conf => conf.uid === value)?.name : '';
        },
      };
    },
    'dropdown': (field) => {
      return {
        name: 'dropdown',
        component: 'SelectElement',
        native: false,
        search: true,
        rules: [field?.mandatory ? 'required' : null].filter(config => config),
        items: options(field?.config),
        get_submitted_value(value) {
          return value ? field?.config.find(conf => conf.uid === value)?.name : '';
        },
      };
    },
    'multi_text': (field) => {
      const { check_split } = useAuthStore();

      async function formatOnLoad(table_settings) {
        if (check_split('multi_text_field_config') && configuration.is_submitted && field.config?.associate_metadata_object?.associate_metadata) {
          const extra_properties = field.config.associate_metadata_object.properties.reduce((accumulator, property) => {
            if (property !== 'name')
              accumulator.push({ key: property, value: null });

            return accumulator;
          }, []);

          const { data } = await $services.features.get_feature_properties_operations({
            body: {
              query: table_settings.data.map(text => text),
              projects: field.config.associate_metadata_object.lookup_projects,
              extraProperties: extra_properties,
              op: 'get',
            },
          });
          const feature_map = keyBy(data, 'name');

          table_settings.colHeaders.push(...field.config.associate_metadata_object.properties);
          table_settings.columns = ['name', ...field.config.associate_metadata_object.properties].map(property => ({
            data: property,
            readOnly: true,
            wordWrap: true,
          }));

          const table_row_data = table_settings.data.map((row_name) => {
            return feature_map[row_name] ? feature_map[row_name] : { name: row_name };
          });

          return { ...table_settings, data: table_row_data.map(value => ({ ...value })) };
        }

        return { ...table_settings, data: table_settings.data.filter(value => value).map(value => ({ text: value })) };
      }

      return {
        name: 'multi text',
        component: 'SheetElement',
        options: {
          heading: field.name,
          placeholder: 'Enter codes',
          table_settings: {
            columns: [
              {
                data: 'text',
                wordWrap: true,
              },
            ],
            colHeaders: ['Details'],
          },
          format_data: data => uniq(data?.flat()?.filter(value => value) || []),
          format_load: formatOnLoad,
        },
        native: false,
        search: true,
        rules: [field?.mandatory ? 'required' : null].filter(config => config),
        get_submitted_value(value) {
          const entry_text = value?.length > 1 ? 'entries' : 'entry';
          return value ? `${value.length} ${entry_text}` : '-';
        },
      };
    },
    'group': () => {
      return {
        name: 'container2',
        component: 'GroupElement',
      };
    },
    'number': (field) => {
      function getFormattedValue(value) {
        if (value == null)
          return null;
        else
          return +value;
      }
      return {
        'name': 'number',
        'component': 'TextElement',
        'placeholder': field?.config?.placeholder,
        'input-type': 'number',
        'format-data': (n, v) => ({ [n]: v === '' ? '' : getFormattedValue(v) }),
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable'], ['', 'numeric'], ['min:', field?.config?.minimum], ['max:', field?.config?.maximum]]),
        'addons': { before: field?.config?.prefix, after: field?.config?.units },
        get_submitted_value(value) {
          const has_value = !Number.isNaN(value) && value !== undefined && value !== null;
          const prefix = (field?.config?.prefix ? field?.config?.prefix : '');
          const units = (field?.config?.units ? field?.config?.units : '');
          return has_value ? prefix + value + units : '-';
        },
      };
    },
    'Slider': (field) => {
      const prefix = field?.config?.prefix || '';
      const units = field?.config?.units || '';
      return {
        name: 'slider',
        component: 'SliderElement',
        step: 1,
        default: field?.config?.minimum ? +field?.config?.minimum : 0,
        addClass: { wrapper: '!text-sm' },
        max: field?.config?.maximum ? +field?.config?.maximum : 100,
        min: field?.config?.minimum ? +field?.config?.minimum : 0,
        rules: [field?.mandatory ? 'required' : null].filter(config => config),
        format: { suffix: units, prefix, decimals: 0 },
        get_submitted_value(value) {
          const prefix = field?.config?.prefix ? field?.config?.prefix : '';
          const units = field?.config?.units ? field?.config?.units : '';
          return value ? prefix + value + units : '';
        },
      };
    },
    'date_time': (field) => {
      const time_display_format = field?.config?.type === 'time' ? 'h:mm a' : 'LLL dd, yyyy';
      const get_submitted_value_time = value => field?.config?.type === 'time' ? dayjs(value).format('LT') : dayjs(value).format('DD/MM/YYYY');
      const minimum_date_format = field?.config?.type === 'datetime' ? `${dayjs().format('YYYY-MM-DD hh:mm a')}` : dayjs().format('YYYY-MM-DD');
      return {
        'name': 'datetime',
        'component': 'DateTimeElement',
        'options': {
          'placeholder': field?.config?.dateplaceholder,
          'calendarCellClassName': 'w-8 h-8',
          'format': field?.config?.type === 'datetime' ? 'LLL dd, yyyy, h:mm a' : time_display_format,
          'min-date': field?.config?.can_select_past_dates ? '' : minimum_date_format,
          'is-24': false,
          'enableTimePicker': field?.config?.type === 'datetime',
          'time-picker': field?.config?.type === 'time',
        },
        'rules': [field?.mandatory ? 'required' : 'nullable'].filter(config => config),
        'load-format': 'YYYYYY-MM-DDTHH:mm:ss.sssZ',
        'format-data': (n, v) => {
          if (v)
            return { [n]: field?.config?.type === 'time' ? dayjs(`${v.hours}:${v.minutes}`, 'H:m').toISOString() : new Date(v).toISOString() };
          else
            return { [n]: undefined };
        },
        'format-load': (data) => {
          let date_time = data;
          if (field?.config?.type === 'time')
            date_time = { hours: dayjs(data).hour(), minutes: dayjs(data).minute() };

          return date_time;
        },
        get_submitted_value(value) {
          if (!value)
            return '-';
          return field?.config?.type === 'datetime' ? dayjs(value).format('DD/MM/YYYY, h:mm A') : get_submitted_value_time(value);
        },
      };
    },
    'email': (field) => {
      return {
        'name': 'email',
        'component': 'TextElement',
        'placeholder': field?.config?.placeholder,
        'input-type': 'email',
        'rules': add_rule([['', 'email'], ['', field?.mandatory ? 'required' : 'nullable']]),
        get_submitted_value(value) {
          return value || '-';
        },
      };
    },
    'url': (field) => {
      return {
        'name': 'url',
        'component': 'TextElement',
        'placeholder': field?.config?.placeholder,
        'input-type': 'url',
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable'], ['', 'regex:/^(?:(?:https?|ftp):\\/\\/)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00A1-\\uFFFF0-9]-*)*[a-z\\u00A1-\\uFFFF0-9]+)(?:\\.(?:[a-z\\u00A1-\\uFFFF0-9]-*)*[a-z\\u00A1-\\uFFFF0-9]+)*(?:\\.(?:[a-z\\u00A1-\\uFFFF]{2,})))(?::\\d{2,5})?(?:\\/\\S*)?$/']]),
        get_submitted_value(value) {
          return value || '-';
        },
      };
    },
    'attachment': (field) => {
      const form_uid = configuration?.form_detail?.uid || 'fields';
      const get_additional_description = () => {
        const file_size = field?.config?.option ? `Max file size: ${field?.config?.option}MB` : '';
        const accepted_file_types = field?.config?.custom !== 'all' && field?.config?.type?.length > 0 ? `Allowed file types: ${field?.config?.type.join(', ')}` : '';
        const maximum_no_of_files = field?.config?.limit_no_of_files >= 0 ? `Max no of files: ${field?.config?.limit_no_of_files}` : '';
        return [file_size, maximum_no_of_files, accepted_file_types].filter(description => description).join(', ');
      };
      return {
        'name': 'file',
        'component': 'MultifileElement',
        'after': get_additional_description(),
        'auto': false,
        'drop': true,
        'options': {
          resource: JSON.stringify({
            uid: `${form_uid}/${field.uid}/`,
          }),
        },
        'format-data': (n, v) => {
          const { logged_in_user_details } = useAuthStore();
          const now = new Date().toISOString();
          return { [n]: v.map(file => file?.service ? file : ({ service: file?.service_object, file_name: file?.name || file?.fname, file_size: file?.size || file?.file_size, mime_type: file?.type || file?.mime_type, owner: { uid: logged_in_user_details?.user_id }, created_at: now })).filter(file => file?.service) };
        },
        'accept': field?.config?.custom !== 'all' && field?.config?.type?.length > 0 ? select_file_type(field?.config?.type) : '',
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable'], ['max:', field?.config?.limit_no_of_files >= 0 ? field?.config?.limit_no_of_files : null]]),
        'file': field?.config?.option ? { rules: `max:${field?.config?.option * 1000}` } : undefined,
        get_submitted_value(value) {
          if (Array.isArray(value))
            return value.length > 0 ? value : '-';
          else
            return value ? [value] : '-';
        },
      };
    },
    'info': (field) => {
      return {
        name: 'info',
        component: 'WysiwygEditorElement',
        options: {
          editor_enabled: false,
          plugins: ['images', 'tables', 'tags'],
          placeholder_text: 'Enter info here',
          modelValue: field?.config?.placeholder,
          enable_events: true,
        },
        slots: {
          after: FormInfoAttachments,
          attachments: field?.config?.attachments ?? [],
        },
        columns: {
          default: { container: 12, wrapper: 12, label: 12 },
          sm: { container: 12, label: 12, wrapper: 12 },
          md: { container: 12, label: 12, wrapper: 12 },
        },
        disabled: true,
        get_submitted_value(value) {
          return field?.config?.placeholder;
        },
      };
    },
    'money': (field) => {
      const getFormattedValue = value => value != null ? +value : null;
      return {
        'name': 'money',
        'component': CurrencyElement,
        'input-type': 'number',
        'currency_options': {
          placeholder: field?.config?.placeholder,
          hide_dropdown: true,
        },
        'format-data': (n, v) => ({ [n]: v === '' ? '' : getFormattedValue(v) }),
        'format-load': v => v != null ? `${v}` : v,
        'currency_code': field?.config?.code,
        'rules': add_rule([['', 'numeric'], ['', field?.mandatory ? 'required' : 'nullable']]),
        get_submitted_value(value) {
          return value != null ? `${currencies[field?.config?.code || 'USD']?.symbol} ${value}` : '';
        },
      };
    },
    'signature': (field) => {
      const format_signature_data = (name, file) => {
        if (!file)
          return { [name]: undefined };
        const parsed_file = file?.service ? file : ({ service: file.service_object, file_name: file.name, file_size: file.size, mime_type: file.type, meta: file.meta });
        if (file instanceof File)
          Object.defineProperty(parsed_file, '_file', {
            value: file,
            enumerable: false,
          });
        return { [name]: parsed_file };
      };

      return {
        'name': 'signature',
        'component': 'SignatureElement',
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable']]),
        'format-data': format_signature_data,
        'format-load': v => v?.[0] || v,
        get_submitted_value(value) {
          return value != null ? value : '';
        },
      };
    },
    'phone': (field) => {
      const code = field?.config?.code || '+1';
      return {
        'component': 'PhoneElement',
        'placeholder': field?.config?.placeholder,
        'include': [field?.config?.iso ? field?.config?.iso : 'US'],
        'default': field?.config?.code || '+1',
        'allow-incomplete': true,
        'unmask': true,
        'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable']]),
        'format-data': (n, v) => ({ [n]: v === '' ? '' : v.split(code)[1] || v }),
        'format-load': v => code + v,
        get_submitted_value(value) {
          const getCountryForField = country => country.iso2 === field?.config?.iso;
          const getCountry = () => all_countries.find(getCountryForField);
          let code = field?.config?.code || getCountry()?.dialCode;
          code = code ? `+${code}` : '';
          return value ? `${code} ${value}` : '';
        },
      };
    },
    'members': (field) => {
      const has_allowed_members = !!field.config.allowed_members?.members.length;
      const common_store = useCommonStore();
      const get_allowed_members_uid = () => {
        const members = [];
        const teams = [];

        const should_extract_member = field.config.allowed_members?.extract_member;

        for (const member_data of field.config.allowed_members.members)
          if (member_data.member) {
            members.push(member_data.uid);
          }
          else if (should_extract_member && !member_data.member) {
            const team_users = common_store.get_team(member_data.uid).users;
            teams.push(...team_users);
          }
          else {
            teams.push(member_data.uid);
          }

        return uniq([...members, ...teams]);
      };

      return {
        name: 'member',
        component: hawkAssigneeInput,
        options: {
          'name': 'member',
          'label': field.name,
          'description': field.description,
          'canClear': true,
          'size': 'sm',
          'has_teams': true,
          'rules': add_rule([['', field?.mandatory ? 'required' : 'nullable']]),
          'format-data': (n, v) => ({ [n]: v ? formatMemberTeam(v) : '' }),
        },
        ...(field.config.allowed_members?.members.length
          ? {
            has_custom_options: has_allowed_members,
            custom_options: get_allowed_members_uid(),
          }
          : {}),
        format_load: true,
        multi: field?.config?.allow_multiple_selection,
        get_submitted_value(value) {
          if (!Array.isArray(value))
            return '-';
          const assignees = { members: [], teams: [] };
          value.forEach((assignee_uid) => {
            if (common_store.get_user(assignee_uid))
              assignees.members.push(assignee_uid);
            else if (common_store.get_team(assignee_uid))
              assignees.teams.push(assignee_uid);
          });
          if (assignees.members.length === 0 && assignees.teams.length === 0)
            return '-';
          return assignees;
        },
      };
    },
    'auto_numbering': (field) => {
      return {
        name: 'auto_number',
        component: 'TextElement',
        disabled: true,
        placeholder: configuration.is_builder ? field?.auto_number_data?.preview : 'This field will be auto generated',
        get_submitted_value(value) {
          return value?.value || '';
        },
      };
    },
    'formula': (field) => {
      return {
        name: 'formula',
        component: 'TextElement',
        disabled: true,
        placeholder: 'This field will be auto generated',
        get_submitted_value(value) {
          return value || '';
        },
      };
    },
  };
}

export function getSubmittedFieldData(field, field_value) {
  try {
    const field_vue_forms_map = useFormFieldConfiguration();
    const representation = (field?.config?.representation && field?.config?.representation !== 'Default') ? field?.config?.representation : field?.properties?.type;
    if (!field_vue_forms_map[representation])
      return '-';
    const field_config = field_vue_forms_map[representation](field);
    const get_submitted_value = field_config?.get_submitted_value ? field_config?.get_submitted_value : field_value => field_value;
    return get_submitted_value(field_value) || '-';
  }
  catch (e) {
    logger.log(e);
  }
}

export function getFieldComponentData(field, configuration) {
  try {
    const field_vue_forms_map = useFormFieldConfiguration(configuration);

    const representation = (field?.config?.representation && field?.config?.representation !== 'Default') ? field?.config?.representation : field?.properties?.type;
    const form_field_data = field_vue_forms_map[representation]?.(field) || {};
    const options = form_field_data.options ? { options: { ...form_field_data.options } } : {};

    return {
      ...form_field_data,
      name: field?.uid,
      label: field?.name,
      description: field?.description,
      ...options,
    };
  }
  catch (e) {
    logger.log(e);
  }
}

export function getFieldComponentType(field, is_submitted) {
  try {
    const configuration = getFieldComponentData(field);
    return is_submitted ? 'StaticElement' : configuration?.component;
  }
  catch (e) {
    logger.log(e);
  }
}
