<script setup>
import { cloneDeep } from 'lodash-es';
import HOT from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';
import sampleData from './sample-data.json';

const dropdownOptions = ['BMW', 'Chrysler', 'Nissan', 'Suzuki', 'Toyota', 'Volvo'];
const columns = [
  {
    data: 'Text field',
    text: 'Text field',
    type: 'text',
    className: 'htLeft htMiddle',
    renderer: 'suffixPrefixRenderer',
    suffix: 'Suffix',
    readOnly: true,
  },
  {
    data: 'Number',
    text: 'Number',
    type: 'numeric',
    renderer: 'suffixPrefixRenderer',
    prefix: '$',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Text area',
    text: 'Text area',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Yes/No',
    text: 'Yes/No',
    renderer: 'triStateRenderer',
    className: 'htLeft htMiddle',
    editor: 'triStateCheckboxEditor',
  },
  {
    data: 'Checkbox',
    text: 'Checkbox',
    className: 'htLeft htMiddle',
    renderer: 'multiSelectRenderer',
    editor: 'multiSelectAutocompleteEditor',
    options: ['Option-A', 'Option-B', 'Option-C', 'Option-D', 'Option-E'], // Available options for multi-select
  },
  {
    data: 'Radio',
    text: 'Radio',
    type: 'autocomplete',
    source: dropdownOptions,
    strict: true,
    className: 'htLeft htMiddle',

  },
  {
    data: 'Dropdown',
    text: 'Dropdown',
    source: dropdownOptions,
    type: 'autocomplete',
    strict: true,
    className: 'htLeft htMiddle',
  },
  {
    data: 'Member',
    text: 'Member',
    renderer: 'membersRenderer',
    className: 'htLeft htMiddle',
  },
  {
    data: 'File upload',
    text: 'File upload',
    renderer: 'fileUploadRenderer',
    className: 'htLeft htMiddle',
    editor: false,
  },
  {
    data: 'Date & Time',
    text: 'Date & Time',
    type: 'date',
    dateFormat: 'MM/DD/YYYY',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Email',
    text: 'Email',
    renderer: 'emailRenderer',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Phone number',
    text: 'Phone number',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Money',
    text: 'Money',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Signature',
    text: 'Signature',
    className: 'htLeft htMiddle',
    editor: false,
  },
  {
    data: 'Website',
    text: 'Website',
    renderer: 'urlRenderer',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Info',
    text: 'Info',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Auto number',
    text: 'Auto number',
    className: 'htLeft htMiddle',
  },
  {
    data: 'Multi text',
    text: 'Multi text',
    editor: false,
    className: 'htLeft htMiddle',
  },
  {
    data: 'Formula',
    text: 'Formula',
    className: 'htLeft htMiddle',
  },
];
const state = reactive({
  data: [],
  columns: [],
  enable_slugs: true,
  row_sorting: false,
  content_wrap: true,
  auto_row_wrap: true,
  auto_column_wrap: true,
  rerender: 0,
  rearrange_rows: false,
  rearrange_columns: false,
  display_expand_icon: false,
  adding_rows: true,
  column_summary: false,
  detailed_summary: false,
  instance: null,
  disable_attachments: false,

});

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
  };
}

function toggleAction(action) {
  state[action] = !state[action];
  if (action === 'column_summary') {
    state.detailed_summary = false;
    if (state[action]) {
      state.data.push({
        disableEdit: true,
      });
    }
    else {
      state.data = cloneDeep(sampleData);
    }
  }
  if (action === 'detailed_summary') {
    state.column_summary = false;
    if (state[action]) {
      state.data.push({
        disableEdit: true,
      }, {
        disableEdit: true,
      }, {
        disableEdit: true,
      });
    }
    else {
      state.data = cloneDeep(sampleData);
    }
  }
  if (action === 'disable_attachments') {
    if (state[action]) {
      state.columns = state.columns.filter(item => item.renderer !== 'fileUploadRenderer');
      state.instance.updateSettings({

        columns: state.columns.filter(item => item.renderer !== 'fileUploadRenderer'),
      });
    }
    else {
      state.columns = columns;
      state.instance.updateSettings({

        columns: state.columns,
      });
    }
  }
  state.rerender += 1;
}

function columnSummary() {
  if (state.column_summary) {
    return [
    // configure a column summary
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 0,
        destinationColumn: 1,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction(endpoint) {
          logger.log('🚀 ~ customFunction ~ endpoint:', endpoint);

          return 2;
        },
        forceNumeric: true,
      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 0,
        destinationColumn: 3,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction(endpoint) {
          logger.log('🚀 ~ customFunction ~ endpoint:', endpoint);

          return '123';
        },

      },
    ];
  }
  else {
    return [
    // configure a column summary
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 2,
        destinationColumn: columns.length - 2,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'First summary';
        },

      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 1,
        destinationColumn: columns.length - 2,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'Second summary';
        },

      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 0,
        destinationColumn: columns.length - 2,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'Third summary';
        },

      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 2,
        destinationColumn: columns.length - 1,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'First summary value';
        },

      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 1,
        destinationColumn: columns.length - 1,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'Second summary value';
        },

      },
      {
      // set the `type` option to `'custom'`
        type: 'custom',
        destinationRow: 0,
        destinationColumn: columns.length - 1,
        reversedRowCoords: true,
        // add your custom summary function
        customFunction() {
          return 'Third summary value';
        },

      },
    ];
  }
}
function columnSummaryConfig() {
  return {
    show: state.column_summary || state.detailed_summary,
    type: state.detailed_summary ? 'detailed' : 'column',
    summaryFunction: columnSummary,
    ...(state.detailed_summary && { summaryRowsLength: 3 }),
  };
}
onMounted(async () => {
  try {
    state.data = cloneDeep(sampleData);
    state.columns = columns;
  }
  catch (err) {
    logger.error(err);
  }
});
</script>

<template>
  <div class="p-5">
    <div class="flex justify-between items-center flex-wrap gap-2 text-black mb-10">
      <HawkCheckbox id="slugs" :model-value="state.enable_slugs" @input="toggleAction('enable_slugs')">
        <HawkText content="Enable slugs" />
      </HawkCheckbox>
      <HawkCheckbox id="display_expand_icon" :model-value="state.display_expand_icon" @input="toggleAction('display_expand_icon')">
        <HawkText content="Display expand icon" />
      </HawkCheckbox>
      <HawkCheckbox id="rearrange_columns" :model-value="state.rearrange_columns" @input="toggleAction('rearrange_columns')">
        <HawkText content="Rearrange columns" />
      </HawkCheckbox>
      <HawkCheckbox id="rearrange_rows" :model-value="state.rearrange_rows" @input="toggleAction('rearrange_rows')">
        <HawkText content="Rearrange rows" />
      </HawkCheckbox>
      <HawkCheckbox id="auto_column_wrap" :model-value="state.auto_column_wrap" @input="toggleAction('auto_column_wrap')">
        <HawkText content="Auto column wrap" />
      </HawkCheckbox>
      <HawkCheckbox id="auto_row_wrap" :model-value="state.auto_row_wrap" @input="toggleAction('auto_row_wrap')">
        <HawkText content="Auto row wrap" />
      </HawkCheckbox>
      <HawkCheckbox id="adding_rows" :model-value="state.adding_rows" @input="toggleAction('adding_rows')">
        <HawkText content="Adding rows" />
      </HawkCheckbox>
      <HawkCheckbox id="row_sorting" :model-value="state.row_sorting" @input="toggleAction('row_sorting')">
        <HawkText content="Row sorting" />
      </HawkCheckbox>
      <HawkCheckbox id="content_wrap" :model-value="state.content_wrap" @input="toggleAction('content_wrap')">
        <HawkText content="Content wrap" />
      </HawkCheckbox>
      <HawkCheckbox id="column_summary" :model-value="state.column_summary" @input="toggleAction('column_summary')">
        <HawkText content="Column summary" />
      </HawkCheckbox>
      <HawkCheckbox id="detailed_summary" :model-value="state.detailed_summary" @input="toggleAction('detailed_summary')">
        <HawkText content="Detailed summary" />
      </HawkCheckbox>
      <HawkCheckbox id="disable_attachments" :model-value="state.disable_attachments" @input="toggleAction('disable_attachments')">
        <HawkText content="Remove attachments" />
      </HawkCheckbox>
    </div>
    <HOT
      :key="state.rerender"
      :auto-wrap-col="state.auto_column_wrap"
      :auto-wrap-row="state.auto_row_wrap"
      :adding-rows="state.adding_rows"
      :content-wrap="state.content_wrap"
      :column-summary-config="columnSummaryConfig()"
      :display-expand-icon="state.display_expand_icon"
      :enable-slugs="state.enable_slugs"
      :manual-column-move="state.rearrange_columns"
      :manual-row-move="state.rearrange_rows"
      :row-sorting="state.row_sorting"
      :data="state.data"
      :columns="state.columns"
      :hot_settings="hotSettings()"
      column-summary-type="column"
      :row-header-icon="state.display_expand_icon"
      hot-table-id="generic-hot"
      :col-headers="index => state.columns[index]?.text"
      height=" 700px" @ready="state.instance = $event"
    />
  </div>
</template>
