<!-- eslint-disable prefer-rest-params -->
<!-- eslint-disable no-alert -->
<script setup>
import dayjs from 'dayjs';
import parser from 'dayjs-parser';
import dompurify from 'dompurify';
import { onMounted, onUnmounted } from 'vue';
import { useMembers } from '~/common/composables/members.js';
import { load_js_css_file } from '~/common/utils/load-script.util';

const props = defineProps({
  hotSettings: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  hotTableId: {
    type: String,
    default: 'hawk-handsontable',
  },
  triStateValuesMap: {
    type: Object,
    default: () => ({
      yes: 'yes',
      no: 'no',
      intermediate: 'N/A',
    }),
  },
  columnsMenu: {
    type: Object,
    default: () => (null),
  },
  cells: {
    type: Function,
    default: null,
  },
  height: {
    type: String,
    default: '600px',
  },
  width: {
    type: String,
    default: '100%',
  },
  nestedHeaders: {
    type: Array,
    default: () => [],
  },
  manualRowMove: {
    type: Boolean,
    default: true,
  },
  enableSlugs: {
    type: Boolean,
    default: false,
  },
  manualColumnMove: {
    type: Boolean,
    default: true,
  },
  autoWrapCol: {
    type: Boolean,
    default: true,
  },
  autoWrapRow: {
    type: Boolean,
    default: true,
  },
  rowHeaderIcon: {
    type: Boolean,
    default: true,
  },
  wordWrap: {
    type: Boolean,
    default: true,
  },
  rowSorting: {
    type: Boolean,
    default: true,
  },
  contentWrap: {
    type: Boolean,
    default: true,
  },
  columnSummaryConfig: {
    type: Object,
    default: () => {
      return {
        show: false,
        type: 'column',
        summaryFunction: () => ({}),
      };
    },
  },
  colHeaders: Function,
  afterChange: Function,
  beforeChange: Function,
  afterOnCellMouseDown: Function,
  afterValidate: Function,
  beforeValidate: Function,
  beforeRemoveRow: Function,
  afterRemoveRow: Function,
  afterSelection: Function,
  afterSelectionEnd: Function,
  beforeOnCellMouseDown: Function,
  afterGetColHeader: Function,
});
const emit = defineEmits(['ready', 'insertColLeft', 'insertColRight', 'deleteCol', 'deleteRow', 'afterChange', 'afterCellEdit', 'afterRowEdit', 'slugClicked', 'rowHeaderIconClick', 'cellDblClick', 'onEnterPress']);
dayjs.extend(parser);
const { getUserDetails } = useMembers();
const state = reactive({
  hotInstance: null,
});
const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];
watch(
  () => props.data,
  (newData) => {
    if (state.hotInstance)
      state.hotInstance.loadData(newData);
  },
);

watch(
  () => props.columns,
  (newColumns) => {
    if (state.hotInstance)
      state.hotInstance.updateSettings({ columns: newColumns });
  },
);
function isSummaryRow(row, instance, _cellProperties) {
  if (!props.columnSummaryConfig?.show)
    return;
  if (props.columnSummaryConfig?.type === 'column') {
    return row === instance.countRows() - 1;
  }
  else if (props.columnSummaryConfig?.type === 'detailed') {
    return row > instance.countRows() - props.columnSummaryConfig?.summaryRowsLength - 1;
  }
}
function stringToNumber(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}
// 1. Phone Number Renderer
function phoneNumberRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const countryCode = value.code || '';
  const phoneNumber = value.number || '';
  td.innerHTML = `+${countryCode} - ${phoneNumber}`;
  td.className = 'htMiddle htCenter';
  return td;
}

// 2. URL Renderer
function urlRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const anchor = document.createElement('a');
  anchor.href = value?.includes('https://') ? value : `https://${value}`;
  anchor.textContent = value;
  anchor.target = '_blank';
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  td.appendChild(anchor);
  return td;
}

// 3. Email Renderer
function emailRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const anchor = document.createElement('a');
  anchor.href = `mailto:${value}`;
  anchor.textContent = value;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  td.appendChild(anchor);
  return td;
}

// 4. File Upload (3x3 Grid) Renderer
function fileUploadRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const grid = document.createElement('div');
  grid.style.display = 'grid';
  grid.style.gridTemplateColumns = 'repeat(3, 1fr)';
  grid.style.gridGap = '4px';
  grid.style.padding = '4px';

  value?.forEach((fileUrl) => {
    const img = document.createElement('img');
    img.src = fileUrl;
    img.style.width = '75px';
    img.style.height = '75px';
    img.style.borderRadius = '4px';
    img.style.objectFit = 'cover';
    grid.appendChild(img);
  });

  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  td.appendChild(grid);
  return td;
}

// 5. Signature Renderer
function signatureRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  const { name, date } = value;
  td.innerHTML = `${name} [${new Date(date).toLocaleDateString()}]`;
  return td;
}

// 6. Members Renderer
function membersRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  const { members_details } = getUserDetails(value);
  members_details?.forEach((user) => {
    if (user) {
      const display_name = user.name;

      const user_details = {
        bg_color: stringToNumber(user?.name),
        label: dompurify.sanitize(display_name.trim(), { ALLOWED_TAGS: [] }),
        id: user.uid,
        avatar: user?.display_picture ?? '',
        email: dompurify.sanitize(user?.email, { ALLOWED_TAGS: [] }),
      };

      const container = document.createElement('div');
      container.style.display = 'flex';
      container.classList = 'gap-1';
      container.style.alignItems = 'center';
      container.style.marginBottom = '4px';
      if (user_details.avatar) {
        const img = document.createElement('img');
        img.src = user?.display_picture ?? '';
        img.className = 'rounded-full object-cover';
        img.style.width = '24px';
        img.style.height = '24px';
        container.appendChild(img);
      }
      else {
        const span = document.createElement('span');
        span.className = 'rounded-full';
        span.style.width = '24px';
        span.style.height = '24px';
        span.style.backgroundColor = user_details?.bg_color;
        span.textContent = user_details.label?.substring(0, 1);
        span.style.color = 'white';
        span.style.alignContent = 'center';
        container.appendChild(span);
      }
      const name = document.createElement('span');
      name.textContent = user_details.label; // Assume the array has `name` property

      container.appendChild(name);
      td.appendChild(container);
    }
  });
  return td;
}

// 7. Multiselect Renderer
function multiselectRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  // eslint-disable-next-line prefer-rest-params
  Handsontable.renderers.TextRenderer.apply(this, arguments);
  td.textContent = value?.join(', ');
  return td;
}

// 8. Yes/No/NA Renderer (Tri-state Checkbox)
function triStateRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = '';
  td.className = 'htMiddle htCenter';
  // Create the checkbox element
  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.style.width = '12px';
  checkbox.style.height = '12px';
  checkbox.style.pointerEvents = 'none';
  // Create a label to style the checkbox text (yes, no, Intermediate)
  const label = document.createElement('span');

  // Handle the three states: 'yes', 'no', 'Intermediate'
  if (value?.toLowerCase() === props.triStateValuesMap.yes?.toLowerCase()) {
    checkbox.checked = true;
  }

  else if (value?.toLowerCase() === props.triStateValuesMap.no?.toLowerCase()) {
    checkbox.classList.add('custom-no-state');
  } // Custom class for 'No'

  else if (value?.toLowerCase() === props.triStateValuesMap.intermediate?.toLowerCase()) {
    checkbox.indeterminate = true;
    checkbox.classList.add('custom-intermediate-state');
  }

  // Apply strikethrough and grey style for the Intermediate state

  // Append the checkbox and label to the cell
  td.appendChild(checkbox);
  td.appendChild(label);
}

// 9. Multi-text Renderer
function multiTextRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (isSummaryRow(row, instance, cellProperties))
    return td;
  td.innerHTML = `${value?.join(', ')} (${value.length} entries)`;
  return td;
}
function customRowHeaderRenderer(row, TH) {
  TH.className = 'htMiddle htCenter'; // Custom class for styling
  // Call the default row header renderer
  if (props.columnSummaryConfig?.show && isSummaryRow(row, this, {})) {
    TH.innerHTML = '';
    return;
  }
  if (!TH.querySelector('.row-icon') && props.rowHeaderIcon) {
  // Create an icon element (you can replace this with your icon logic)
    const icon = document.createElement('span');
    icon.classList.add('row-icon', 'ml-2', 'cursor-pointer'); // Tailwind classes for margin and pointer

    icon.innerHTML = '⭐'; // Example icon (could be an image or font icon)
    // Add click event listener to the icon
    icon.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent clicking on the row itself
      // eslint-disable-next-line no-alert
      alert(`Icon clicked on row: ${row}`);
      emit('rowHeaderIconClick', row);
    });
    // Append the icon after the row header text
    TH.appendChild(icon);
  }
}
function suffixPrefixRenderer(instance, td, row, col, prop, value, cellProperties) {
  const type = cellProperties.type;
  if (type === 'text')
    Handsontable.renderers.TextRenderer.apply(this, arguments);
  else if (type === 'numeric')
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  td.innerHTML = `${cellProperties.prefix ? `<span>${cellProperties.prefix}</span> ` : ''}${value}${cellProperties.suffix ? ` <span>${cellProperties.suffix}</span>` : ''}`;
}
// Helper function to format date using dayjs-parser
function formatDate(value) {
  const parsedDate = dayjs(value); // Adjust format as needed
  if (parsedDate.isValid()) {
    return parsedDate.format('MM/DD/YYYY'); // Return formatted date
  }
  return ''; // Return empty string if invalid date
}

// Helper function to format time using dayjs-parser
function formatTime(value) {
  const parsedTime = dayjs(value); // Adjust format as needed
  if (parsedTime.isValid()) {
    return parsedTime.format('HH:mm'); // Return formatted time
  }
  return ''; // Return empty string if invalid time
}
function handleDblClick() {
  const selected_cell = state.hotInstance.getSelectedLast();
  const row = selected_cell?.[0];
  const column = selected_cell?.[1];
  const cellMeta = state.hotInstance.getCellMeta(row, column);
  emit('cellDblClick', { ...(cellMeta || {}), value: state.hotInstance?.getDataAtCell(row, column) });
}
function fixedRowsBasedOnSummaryConfig() {
  if (props.fixedRowsBottom)
    return props.fixedRowsBottom;
  if (props.columnSummaryConfig?.show && props.columnSummaryConfig?.type === 'column') {
    return 1;
  }
  if (props.columnSummaryConfig?.show && props.columnSummaryConfig?.type === 'detailed') {
    return props.columnSummaryConfig?.summaryRowsLength;
  }
  return null;
}
async function loadScripts() {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/choices.js/public/assets/scripts/choices.min.js',
    'choices-js',
    'js',
  );
  await load_js_css_file(
    ' https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css',
    'choices-css',
    'css',
  );
  // Figure out version and performance metrics
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
}
async function initHandsontable() {
  const triStateCheckboxEditorModule = await import('~/common/components/organisms/hawk-handsontable/editors/tristate-editor.js');
  const TriStateCheckboxEditor = triStateCheckboxEditorModule.default;
  Handsontable.editors.registerEditor('triStateCheckboxEditor', class extends TriStateCheckboxEditor {
    constructor(hotInstance) {
      // Pass the triStateValuesMap prop when instantiating the editor globally
      super(hotInstance);
      this.setValuesMap(props.triStateValuesMap);
    }
  });
  const multiSelectCheckboxEditorModule = (await import('~/common/components/organisms/hawk-handsontable/editors/multiselect-editor.js'));
  const MultiSelectCheckboxEditor = multiSelectCheckboxEditorModule.default;
  Handsontable.editors.registerEditor('multiSelectAutocompleteEditor', MultiSelectCheckboxEditor);

  const hotSettings = {
    data: props.data,
    columns: props.columns,
    height: props.height,
    width: props.width,
    afterChange(changes) {
      if (changes) {
        changes?.forEach(([row, _prop, _oldValue, _newValue]) => {
          // Some logic...
          emit('afterRowEdit', state.hotInstance?.getSourceDataAtRow(row));
          // emit('afterCellEdit', state.hotInstance.getSourceDataAtCell(row, state.hotInstance.propToCol(prop)));
        });
        emit('afterChange', state.hotInstance?.getSourceData());
      }
    },
    beforeChange: props.beforeChange,
    afterOnCellMouseDown: props.afterOnCellMouseDown,
    afterValidate: props.afterValidate,
    rowHeaders: true,
    dropdownMenu: props.enableSlugs
      ? false
      : props.columnsMenu || {
        items: {
          add_col_left: {
            name: 'Insert column left',
            disabled() {
              return false;
            },
            callback(key) {
              emit('insertColLeft', key);
              alert('Insert column left callback');
            },
            hidden() {
              return state.hotInstance.getSelected()[1] === 0; // Hide if Column 1 is selected
            },
          },
          add_col_right: {
            name: 'Insert column right',
            disabled() {
              return false;
            },
            hidden() {
              return state.hotInstance.getSelected()[1] === 0; // Hide if Column 1 is selected
            },
            callback(key) {
              emit('insertColRight', key);
              alert('Insert column right callback');
            },
          },
          clear_column: {
            name: 'Clear column',
          },
          delete_col: {
            name: 'Delete column',
            hidden() {
              return state.hotInstance.getSelected()[1] === 0; // Hide for Column 1
            },
            disabled() {
              return true;
            },
            callback(key) {
              emit('deleteCol', key);
              alert('Delete column callback');
            },

          },
        },
      },
    fixedRowsBottom: fixedRowsBasedOnSummaryConfig(),
    ...(props.columnSummaryConfig?.show ? { columnSummary: props.columnSummaryConfig?.summaryFunction } : {}),
    columnSorting: props.rowSorting,
    viewportRowRenderingOffset: 1000,
    beforeValidate: props.beforeValidate,
    beforeRemoveRow: props.beforeRemoveRow,
    readOnlyCellClassName: 'read-only-cell',
    afterRemoveRow: props.afterRemoveRow,
    afterSelection: props.afterSelection,
    beforeOnCellMouseDown: props.beforeOnCellMouseDown,
    manualRowMove: props.manualRowMove,
    autoWrapCol: props.autoWrapCol,
    autoWrapRow: props.autoWrapRow,
    undo: true,
    manualColumnMove: props.manualColumnMove,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    colHeaders: props.colHeaders,
    fillHandle: true,
    rowHeights: '36px',
    stretchH: 'all',
    afterSelectionEnd(r, c) {
      if (r !== -1 && c !== -1) {
        const cellMeta = this.getCellMeta(r, c);
        if (cellMeta.renderer === 'triStateRenderer') {
          state.hotInstance.getActiveEditor()?.enableFullEditMode();
          state.hotInstance.getActiveEditor()?.beginEditing(state.hotInstance.getDataAtCell(r, c));
        }
      }
    },
    cells(row, _col, _prop) {
      const cellProperties = {};
      const column_name = this.instance.colToProp(_col);
      // Example: Disable editing for a specific row based on some key in the dataset
      if ((this.instance.getDataAtRowProp(row, 'disableColumns') || [])?.includes(column_name) || this.instance.getDataAtRowProp(row, 'disableEdit') === true)
        cellProperties.readOnly = true;
      if (isSummaryRow(row, this.instance, cellProperties)) {
        cellProperties.readOnly = true;
        cellProperties.className = '!bg-gray-100';
        cellProperties.type = 'text';
      }
      return cellProperties;
    },
    afterGetColHeader(col, TH) {
      function createIcon(on_hover = true, callback = () => null) {
        const colMenu = document.createElement('div');
        TH.classList.add('relative');
        if (on_hover) {
          TH.classList.add('group');
          colMenu.classList.add('opacity-0', 'group-hover:opacity-100');
        }
        colMenu.classList.add(
          `column-menu-${col}`,
          'cursor-pointer', // Pointer cursor on hover
          'absolute', // Position it absolutely inside the TH
          'right-2', // Align it to the right
          'top-1/2', // Vertically center it
          'transform', // Enable transform for vertical alignment
          '-translate-y-1/2', // Vertically adjust the centering
          'px-2', // Padding for better clickability
          'hover:bg-gray-200', // Light gray background on hover
          'rounded-md', // Rounded edges for a cleaner look
          'transition-opacity', // Smooth transition for the opacity
          'duration-200', // Timing for the transition
        );

        colMenu.textContent = '⋮'; // Three dots
        if (props.enableSlugs) {
          colMenu.textContent = '</>';
          colMenu.classList.add('text-primary-600', '!text-[10px]');
        }
        // Append the ellipsis to the column header
        TH.appendChild(colMenu);
        // Stop the click event from propagating to Handsontable
        colMenu.addEventListener('mousedown', (event) => {
          event.stopPropagation(); // Prevent the event from bubbling up
        });

        // Attach the click event listener to open the menu
        colMenu.addEventListener('click', (event) => {
          event.stopPropagation();
          callback(event);
        });
      }

      // Stricter clean-up: remove any previously added icons in this header
      const existingIcons = TH.querySelectorAll(`.column-menu-${col}`);
      existingIcons.forEach(icon => icon.remove());
      // Remove any existing ellipsis if already present
      if (col > -1 && !TH.querySelector(`.column-menu-${col}`)) {
        if (props.enableSlugs) {
          createIcon(false, () => emit('slugClicked', col));
        }
      }
    },
    afterInit(isForced) {
      if (isForced) {
        this.updateSettings({
          colWidths(index) {
            const columnWidth = this.getColWidth(index);
            return props.columns[index].width || Math.min(columnWidth, 300); // Apply the max-width logic
          },
        });
      }
    },
    beforePaste(data, coords) {
      for (let i = 0; i < coords.length; i++) {
        const row = coords[i].startRow;
        const col = coords[i].startCol;
        // Check if the target column is numeric
        const cellMeta = state.hotInstance.getCellMeta(row, col);

        if (cellMeta.type === 'numeric') {
        // Process pasted data for numeric cells
          data[i] = data[i].map((value) => {
            if (typeof value === 'string') {
            // Remove commas and other special characters, leaving only digits and decimal points
              return value.replace(/[^0-9.-]/g, '');
            }
            return value;
          });
        }
        // Handle date column
        if (cellMeta.type === 'date') {
          data[i] = data[i].map((value, j) => {
            return formatDate(data[i][j]);
          });
          logger.log('🚀 ~ beforePaste ~ cellMeta:', cellMeta);
        }

        // Handle time column
        if (cellMeta.type === 'time') {
          data[i][j] = formatTime(data[i][j]); // Format time
        }
      }
    },
    afterDocumentKeyDown(event) {
      if (event.keyCode === 13) {
        const selected_cell = state.hotInstance.getSelectedLast();
        const row = selected_cell?.[0];
        const column = selected_cell?.[1];
        const cellMeta = state.hotInstance?.getCellMeta(row, column);
        emit('onEnterPress', { ...(cellMeta || {}), value: state.hotInstance?.getDataAtCell(row, column) });
      }
    },
    contextMenu: {
      callback(key, selection, clickEvent) {
        // Common callback for all options
        logger.log(key, selection, clickEvent);
      },
      items: {

        row_above: {
          name: 'Insert row above',
          disabled() {
          // Disable if row addition is not allowed (you can replace the condition)
            return false;
          },
        },
        row_below: {
          name: 'Insert row below',
          disabled() {
            return false;
          },
        },
        add_col_left: {
          name: 'Insert column left',
          disabled() {
            return true;
          },
          callback(key) {
            emit('insertColLeft', key);
          },
          hidden() {
            return state.hotInstance.getSelected()[1] === 0; // Hide if Column 1 is selected
          },
        },
        add_col_right: {
          name: 'Insert column right',
          disabled() {
            return true;
          },
          hidden() {
            return state.hotInstance.getSelected()[1] === 0; // Hide if Column 1 is selected
          },
          callback(key) {
            emit('insertColRight', key);
          },
        },
        remove_row: {
          name: 'Remove row',
          disabled() {
            return false;
          },

        },
        delete_col: {
          name: 'Remove column',
          hidden() {
            return state.hotInstance.getSelected()[1] === 0; // Hide for Column 1
          },
          disabled() {
            return true;
          },
          callback(key) {
            emit('deleteCol', key);
          },

        },
        undo: {
          name: 'Undo',
          disabled() {
            return !state.hotInstance.undoRedo || !state.hotInstance.undoRedo.isUndoAvailable();
          },
        },
        redo: {
          name: 'Redo',
          disabled() {
            return !state.hotInstance.undoRedo || !state.hotInstance.undoRedo.isRedoAvailable();
          },
        },
        cut: {
          name: 'Cut',
        },
        copy: {
          name: 'Copy',
        },
        paste: {
          name: 'Paste',
        },
      },
    },
    ...(props.cells && { cells: props.cells }),
    ...(props.nestedHeaders?.length && { nestedHeaders: props.nestedHeaders }),
    afterGetRowHeader: customRowHeaderRenderer,
    ...props.hotSettings,
  };
  const container = document.getElementById(props.hotTableId);
  // Register custom renderers
  Handsontable.renderers.registerRenderer('phoneNumberRenderer', phoneNumberRenderer);
  Handsontable.renderers.registerRenderer('urlRenderer', urlRenderer);
  Handsontable.renderers.registerRenderer('emailRenderer', emailRenderer);
  Handsontable.renderers.registerRenderer('fileUploadRenderer', fileUploadRenderer);
  Handsontable.renderers.registerRenderer('signatureRenderer', signatureRenderer);
  Handsontable.renderers.registerRenderer('membersRenderer', membersRenderer);
  Handsontable.renderers.registerRenderer('multiSelectRenderer', multiselectRenderer);
  Handsontable.renderers.registerRenderer('triStateRenderer', triStateRenderer);
  Handsontable.renderers.registerRenderer('multiTextRenderer', multiTextRenderer);
  Handsontable.renderers.registerRenderer('suffixPrefixRenderer', suffixPrefixRenderer);
  state.hotInstance = new Handsontable(container, hotSettings);

  state.hotInstance.validateCells();
  // Force a full render after initialization to fix rendering issues
  setTimeout(() => {
    state.hotInstance.render();
  }, 0);
  emit('ready', state.hotInstance);
  const table_element = document.getElementById(props.hotTableId);

  table_element?.addEventListener('dblclick', handleDblClick);
}

onMounted(async () => {
  await loadScripts();
  initHandsontable();
});
onUnmounted(() => {
  if (state.hotInstance)
    state.hotInstance.destroy();
  const table_element = document.getElementById(props.hotTableId);
  table_element?.removeEventListener('dblclick', handleDblClick);
});
</script>

<template>
  <div :id="hotTableId" />
</template>

<style lang="scss">
.htCore {
 font-family: "Inter var" !important;
 color: rgb(0, 0, 0) !important;
  th{
    font-weight: 500 !important;
    padding: 4px;
    border-right:  1px solid #e8e2e2 !important;
    border-bottom: 1px solid #e8e2e2 !important;
    color: rgba(71, 84, 103, 1) !important;

  }
  th:not(.ht__highlight){
    background-color: rgba(249, 250, 251, 1) !important;
  }
 .handsontable thead th.ht__active_highlight{
    background-color: #8eb0e7 !important;
  }
  thead th:last-child {
    border-right: 1px solid #e8e2e2 !important;
  }
  thead th {
    height: 34px !important;
    vertical-align: middle;
    text-align: left;
    &:nth-child(1) {
      text-align: center;
      border-right:  1px solid #e8e2e2 !important;
    }
  }
  td {
    padding: 4px;
    max-width: 30rem;
    border-right:  1px solid #e8e2e2 !important;
    border-left: none !important;
    border-bottom: 1px solid #e8e2e2 !important;

  }
  td:not(.read-only-cell, .htDisabled){
    color: rgb(71, 84, 103) !important;
  }
  td:last-child{
    border-right: 1px solid #e8e2e2 !important
  }

}
.read-only-cell{
  color: #aca9a9 !important;
}
.handsontable tr:first-child td, .handsontable tr:first-child th{
    border-top: 1px solid #e8e2e2 !important;
  }
.ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable:not(.ht_clone_top) thead tr th:first-child, .ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable tbody tr th{
    border-left: 1px solid #e8e2e2 !important;
  }
  .handsontable.htRowHeaders thead tr th:nth-child(2){
    border-left: none !important;
  }
  /* Style for the 'No' checkbox with a cross */
.custom-no-state {
  position: relative;
  border-radius: 8px;
}
.custom-intermediate-state {
  position: relative;
  border-radius: 8px;
}

.custom-no-state::before {
  content: '✗';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #dc2626;

  font-size: 12px;
  width: 12px;
  height: 12px;
  line-height: 12px;

  text-align: center;
  pointer-events: none;
}

.custom-intermediate-state::before {
  content: '-';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #aca9a9;
  font-size: 12px;

  width: 12px;
  height: 12px;

  line-height: 12px;
  text-align: center;
}
.choices__list--multiple .choices__item{
  background-color: rgb(21 112 239) !important;
}
// /* Default icon */
// .changeType::before {
//   content: ":" !important; /* Default icon (FontAwesome 'filter') */
//   font-family: "FontAwesome";
//   font-style: normal;
//   font-weight: normal;
//   text-decoration: inherit;
// }

// /* Change icon on hover */
// .changeType:hover::before {
//   content: "h"; /* New icon on hover (FontAwesome 'times') */
// }
</style>
